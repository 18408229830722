import React from "react";
import Streams from "./components/Streams/Streams";
import Lineup from "./components/Lineup/Lineup";

class Player extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentStream: 0,
            volume: 100,
            streams: [
                {
                    title: "The Lab",
                    fullLink: "https://www.youtube-nocookie.com/embed/CsTdB4s_zK8?start=440&autoplay=1&enablejsapi=1",
                    lineup: {
                        displayStart: new Date("2010-10-10T18:00:00Z"),
                        displayEnd: new Date("2010-10-11T06:00:00Z"),
                        lineup: [
                            {
                                start: new Date("2010-10-10T18:00:00Z"),
                                end: new Date("2010-10-10T21:00:00Z"),
                                title: "DJ Eeazy-e",
                                subtitle: "Easy Warmup Session",
                            },
                            {
                                start: new Date("2010-10-10T18:00:00Z"),
                                end: new Date("2010-10-11T00:00:00Z"),
                                title: "Christian Löffler",
                                subtitle: "The Lab: Home Sessions",
                                isLive: true,
                            },
                        ],
                    },
                },
                {
                    title: "Main Room",
                    fullLink: "https://www.youtube-nocookie.com/embed/owdva7V2M0o?start=353&autoplay=1&enablejsapi=1",
                    lineup: {
                        lineup: [
                            {
                                start: new Date("2010-10-10T18:00:00Z"),
                                end: new Date("2010-10-11T00:00:00Z"),
                                title: "Max Cooper",
                                subtitle: "Live from the Barbican",
                                isLive: true,
                            },
                        ],
                    },
                },
                {
                    title: "The Basement",
                    fullLink: "https://www.youtube-nocookie.com/embed/ZBMmKToMbD4?start=851&autoplay=1&enablejsapi=1",
                    lineup: {
                        lineup: [
                            {
                                start: new Date("2010-10-10T18:00:00Z"),
                                end: new Date("2010-10-11T00:00:00Z"),
                                title: "E110101",
                                subtitle: "Radio OAM",
                                isLive: true,
                            },
                        ],
                    },
                },
            ],
        };
    }

    render() {
        return <div className="player">{this.renderMain()}</div>;
    }

    renderMain() {
        const { streams, currentStream } = this.state;

        const stream = streams[currentStream];
        console.log(stream);
        console.log(this.state);
        if (!stream) return <div className="nothing-selected"></div>;

        let srcLink;
        if (stream.videoId) {
            srcLink = `https://www.youtube-nocookie.com/embed/${stream.videoId}?autoplay=1&enablejsapi=1`;
        } else if (stream.channelId) {
            srcLink = `https://www.youtube-nocookie.com/embed/live_stream?channel=${stream.channel_id}&autoplay=1&enablejsapi=1`;
        } else if (stream.fullLink) {
            srcLink = stream.fullLink;
        }

        return (
            <>
                <iframe width="100%" height="100%" src={srcLink} allow="autoplay; encrypted-media" frameBorder="0" title="streamy"></iframe>
                <Lineup {...stream.lineup} />
                <Streams streams={streams} current={currentStream} setCurrentStream={this.setCurrentStream} />
            </>
        );
    }

    setCurrentStream = (currentStream) => {
        this.setState({
            currentStream,
        });
    };
}

export default Player;
