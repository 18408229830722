import React from "react";
import "./Lineup.scss";

const Lineup = ({ lineup }) => {
    return (
        <div className="lineup-wrapper">
            <div className="lineup">
                {lineup.map((l, i) => (
                    <div className="lineup-item" style={{ backgroundColor: `rgba(${20 + 20 * i},${20 + 20 * i},${20 + 20 * i},0.5)` }} key={l.title + l.subtitle}>
                        <div className="title">{l.title}</div>
                        <div className="subtitle">{l.subtitle}</div>
                        <div className="time">
                            {l.start.getHours().toString().padStart(2, "0")}:{l.start.getMinutes().toString().padStart(2, "0")} - {l.end.getHours().toString().padStart(2, "0")}:{l.end.getMinutes().toString().padStart(2, "0")}
                        </div>
                        {l.isLive && <div className="live">LIVE</div>}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Lineup;
