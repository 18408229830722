import React from "react";

import "./App.css";

import Player from "./Player";
import Lineup from "./components/Lineup/Lineup";

function App() {
    return (
        <div className="App">
            <Player />
        </div>
    );
}

export default App;
