import React from "react";
import classnames from "classnames";

import "./Streams.scss";

const Streams = ({ streams, current, setCurrentStream }) => {
    return (
        <div className="streams-wrapper">
            <div className="streams">
                {streams &&
                    streams.map((s, i) => (
                        <div
                            className={classnames("stream-item", { current: i === current })}
                            onClick={() => {
                                setCurrentStream(i);
                            }}
                        >
                            <div className="title">{s.title}</div>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default Streams;
